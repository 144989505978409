<template>
  <div class="card p-lg-3 p-2 pb-lg-5 pb-2 mb-1">
    <validation-observer ref="addClientForm">
      <b-row class="d-lg-flex justify-content-between">
        <b-col sm="12" md="6">
          <div>
            <h6 class="mt-1">First Name</h6>
            <validation-provider
              #default="{ errors }"
              name="First Name"
              rules="required"
            >
              <b-form-input
                id="first_name"
                v-model="addForm.first_name"
                :state="errors.length > 0 ? false : null"
                placeholder=""
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div>
            <h6 class="mt-1">Middle Name</h6>
            <b-form-input
              id="middle_name"
              v-model="addForm.middle_name"
              placeholder=""
            />
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div>
            <h6 class="mt-1">Last Name</h6>
            <validation-provider
              #default="{ errors }"
              name="Last Name"
              rules="required"
            >
              <b-form-input
                id="last_name"
                v-model="addForm.last_name"
                :state="errors.length > 0 ? false : null"
                placeholder=""
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div>
            <h6 class="mt-1">Phone</h6>
            <validation-provider
              #default="{ errors }"
              name="Phone"
              rules="required"
            >
              <b-form-input
                id="phone"
                v-model="addForm.phone"
                :state="errors.length > 0 ? false : null"
                placeholder=""
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div>
            <h6 class="mt-1">Birthday</h6>
            <validation-provider
              #default="{ errors }"
              name="Birthday"
              rules="required"
            >
              <b-form-datepicker
                v-model="addForm.birthday"
                class=""
                locale="en"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </div>
        </b-col>

        <b-col sm="12">
          <div>
            <h4 class="mt-3 bold">Auth Details</h4>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div>
            <h6 class="mt-1">Email</h6>
            <validation-provider
              #default="{ errors }"
              name="Email"
              rules="required"
            >
              <b-form-input
                id="clientName"
                v-model="addForm.email"
                :state="errors.length > 0 ? false : null"
                placeholder=""
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div>
            <h6 class="mt-1">Password</h6>
            <validation-provider
              #default="{ errors }"
              name="Name"
              rules="required"
            >
              <b-form-input
                id="password"
                v-model="addForm.password"
                type="password"
                :state="errors.length > 0 ? false : null"
                placeholder=""
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </div>
        </b-col>
        <b-col sm="12">
          <div>
            <h4 class="mt-3 mb-2 bold">Location Details</h4>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="">
            <h6 class="">Address line 1</h6>
            <b-form-input
              id="address1"
              v-model="addForm.address"
              placeholder=""
            />
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div>
            <h6 class="">Address line 2</h6>
            <b-form-input
              id="address2"
              v-model="addForm.address2"
              placeholder=""
            />
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="d-lg-flex d-md-flex">
            <div class="w-lg-50 w-md-50 w-100 mr-2">
              <h6 class="mt-1">City</h6>
              <validation-provider
                #default="{ errors }"
                name="City"
                rules="required"
              >
                <b-form-input
                  id="city"
                  v-model="addForm.city"
                  type="text"
                  placeholder="City"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="w-lg-50 w-md-50 w-100">
              <h6 class="mt-1">State</h6>
              <validation-provider
                #default="{ errors }"
                name="State"
                rules="required"
              >
                <b-form-input
                  id="state"
                  v-model="addForm.state"
                  type="text"
                  placeholder="State"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="d-lg-flex d-md-flex">
            <div class="w-lg-50 w-md-50 w-100 mr-2">
              <h6 class="mt-1">ZIPCODE</h6>
              <b-form-input
                id="zipCode"
                v-model="addForm.zip_code"
                placeholder=""
              />
            </div>
            <div class="w-lg-50 w-md-50 w-100">
              <h6 class="mt-1">Country</h6>
              <validation-provider
                #default="{ errors }"
                name="Country"
                rules="required"
              >
                <v-select
                  id="country"
                  v-model="addForm.country"
                  label="title"
                  :options="countryOption"
                  :get-option-label="getSelected"
                  :get-option-value="getOptionValue"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            class="mt-2"
            @click="handleSubmit()"
          >
            Create
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import { togglePasswordVisibility } from "@core/mixins/ui/forms"
import { email, required } from "@validations"
// eslint-disable-next-line object-curly-newline
import { BButton, BCol, BFormInput, BRow, BFormDatepicker } from "bootstrap-vue"
import Ripple from "vue-ripple-directive"
import vSelect from "vue-select"
import { ValidationObserver, ValidationProvider } from "vee-validate"
import axios from "@/libs/axios"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import errorResponse from "@/libs/axiosError"

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    BRow,
    BCol,
    BFormInput,
    BButton,
    vSelect,
    BFormDatepicker,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      required,
      email,
      countryCodeOptions: [
        { value: "+61", text: "Australia(+61)" },
        { value: "+689", text: "French(+689)" },
      ],
      countryOption: [],
      addForm: {
        first_name: "",
        middle_name: "",
        last_name: "",
        email: "",
        password: "",
        phone: "",
        birthday: "",
        country: "",
        state: "",
        city: "",
        zip_code: "",
        address: "",
        address2: "",
      },
    }
  },
  mounted() {
    this.getCountries()
  },
  methods: {
    // attach file
    handleAttachFiles(e) {
      this.attachFiles = e.target.files
    },
    removeFile(index) {
      this.attachFiles.splice(index, 1)
    },
    getSelected(option) {
      return (option && option.name) || ""
    },
    getOptionValue(option) {
      return (option && option.name) || ""
    },
    // form repeater
    repeateAgain() {
      this.noteItems.push({
        id: (this.nextTodoId += this.nextTodoId),
      })
    },
    removeItem(index) {
      this.noteItems.splice(index, 1)
    },

    async handleSubmit() {
      console.log("hello")
      this.$refs.addClientForm.validate().then((success) => {
        if (success) {
          const formData = {
            client_id: this.$route.params.id,
            first_name: this.addForm.first_name,
            middle_name: this.addForm.middle_name,
            last_name: this.addForm.last_name,
            email: this.addForm.email,
            password: this.addForm.password,
            phone: this.addForm.phone,
            birthday: this.addForm.birthday,
            country: this.addForm.country.name,
            state: this.addForm.state,
            city: this.addForm.city,
            zip_code: this.addForm.zip_code,
            address: this.addForm.address,
            address2: this.addForm.address2,
          }
          axios
            .post("/client/add-user", formData)
            .then((res) => {
              if (res.data.error) {
                console.log(res.data.messages)
                let errorText = ""
                let errorCount = 0
                // eslint-disable-next-line no-restricted-syntax, no-unused-vars
                for (const [key, value] of Object.entries(res.data.messages)) {
                  console.log(key, value)
                  if (errorCount === 0) {
                    errorText += value[0]
                  } else {
                    errorText += ` & ${value[0]} `
                  }
                  // eslint-disable-next-line no-plusplus
                  errorCount++
                }
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: "Something went Wrong!",
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                    text: errorText,
                  },
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `User Addition`,
                    icon: "CoffeeIcon",
                    variant: "success",
                    text: `You have successfully Created Client User!`,
                  },
                })
                this.$router.push({
                  path: "/clients-list",
                })
                this.modalShow = false
              }
            })
            .catch((error) => {
              console.log(error)
              errorResponse(error, this.$router)
              this.modalShow = false
            })
        }
        console.log(success)
      })
    },
    async getCountries() {
      axios
        .get("/countries")
        .then((res) => {
          this.countryOption = res.data.countries
        })
        .catch((error) => {
          console.log(error)
          errorResponse(error, this.$router)
        })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
.button {
  background-color: rgb(3, 77, 133);
  color: #fff;
}

.demo-inline-spacing {
  margin-top: -15px;
}
#present_job {
  overflow: overlay;
}
.title {
  margin-left: 5px;
}
.img {
  margin-right: 10px;
  width: 35px;
  height: 35px;
}
.file_bg {
  background-color: #dcdcdc;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
